<template>
  <b-dropdown
    ref="filter-dropdown"
    variant="none"
    toggle-class="p-0 text-decoration-none"
    menu-class="menu-min-width p-3 "
    no-caret
    boundary="window"
  >
    <template #button-content>
      <b-icon
        class="cursor-pointer"
        icon="filter"
        :variant="hasFilter() ? 'success' : ''"
      ></b-icon>
    </template>
    <b-dropdown-form style="min-width: 240px">
      <MarketplaceFilter
        v-if="type == 'marketPlace'"
        @selectedMarketplace="onSelectMarketplace($event)"
        :marketplacesSelected="filter"
      />
      <PaymentsDaysFilter v-if="type == 'payments'" />
      <PaymentsFilter v-if="type == 'payments'" />
      <StockDaysFilter v-if="type == 'stockNumber'" />
      <LienFilter v-if="type == 'lien'" />
      <RecallFilter v-if="type == 'recall'" />
      <SoldFilter v-if="type == 'sold'" />
      <PriceFilter v-if="type == 'prices'" />
      <AgentsList
        v-if="type == 'user'"
        @selectedAgent="onSelectAgent($event)"
        :agentsSelected="filter"
      />
      <VisualInspectionFilter v-if="type == 'visualInspection'" />
      <WorkOrderFilter v-if="type == 'workOrders'" />
      <ExportReceivedDateFilter v-if="type == 'files'" />
      <div class="text-nowrap">
        <b-form-checkbox-group
          v-model="filter"
          :options="filterList"
          class="mb-3"
          stacked
          small
        />
      </div>
    </b-dropdown-form>
    <b-dropdown-divider></b-dropdown-divider>
    <div class="text-center">
      <div class="float-right">
        <b-button
          size="sm"
          variant="success"
          class="mx-1"
          @click="applyFilters()"
          >Apply</b-button
        >

        <b-button
          size="sm"
          variant="yellow"
          class="mx-1 text-white"
          @click="clearFilter()"
          >Clear</b-button
        >
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { filterList } from "./filtersList";
import { mapState } from "vuex";
import { SET_VEHICLES_INFO_FILTERS } from "../actions";
import { superAdminMixin } from "../mixins";
import AgentsList from "./AgentsList";
import LienFilter from "./LienFilter.vue";
import RecallFilter from "./RecallFilter.vue";
import StockDaysFilter from "./StockDaysFilter.vue";
import PaymentsFilter from "./PaymentsFilter.vue";
import SoldFilter from "./SoldFilter.vue";
import PriceFilter from "./PriceFilter.vue";
import MarketplaceFilter from "./MarketplaceFilter.vue";
import VisualInspectionFilter from "./VisualInspectionFilter.vue";
import WorkOrderFilter from "./WorkOrderFilter";
import PaymentsDaysFilter from "./PaymentsDaysFilter.vue";
import ExportReceivedDateFilter from "./ExportReceivedDateFilter.vue";
export default {
  name: "FilterColum",
  props: {
    type: {
      type: String,
      required: true,
    },
    refreshFunction: {
      type: Function,
      default: () => {},
    },
  },
  mixins: [superAdminMixin],
  components: {
    AgentsList,
    LienFilter,
    RecallFilter,
    StockDaysFilter,
    PaymentsFilter,
    SoldFilter,
    PriceFilter,
    MarketplaceFilter,
    VisualInspectionFilter,
    WorkOrderFilter,
    PaymentsDaysFilter,
    ExportReceivedDateFilter,
  },
  data() {
    return {
      filterList: filterList[this.type],
      filter: [],
    };
  },
  computed: {
    typeFormatted() {
      return filterList[this.type];
    },
    ...mapState({
      vehiclesFilters: (state) => state.superAdminStore.vehiclesInfo.filters,
    }),
    vehiclesStore() {
      return this.vehiclesFilters;
    },
  },
  mounted() {
    this.getFilterFromStore();
  },
  watch: {
    vehiclesStore() {
      // Our fancy notification (2).
      this.getFilterFromStore();
    },
  },

  methods: {
    formatTypeToFilter(type) {
      switch (type) {
        case "user":
          return "agentId";
        case "stockNumber":
          return "stock";
        case "conditionalReports":
          return "cr";
        case "files":
          return "export";
        default:
          return type;
      }
    },
    applyFilters() {
      if (this.vehiclesFilters.status == "sold") {
        this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
          reset: false,
          fields: {
            status: "sold",
            smallerThan: this.vehiclesFilters.smallerThan,
            greaterThan: this.vehiclesFilters.greaterThan,
            smallerThanRecall: this.vehiclesFilters.smallerThanRecall,
            greaterThanRecall: this.vehiclesFilters.greaterThanRecall,
            stockDateFrom: this.vehiclesFilters.stockDateFrom,
            stockDateTo: this.vehiclesFilters.stockDateTo,
            paymentSmallerThan: this.vehiclesFilters.paymentSmallerThan,
            paymentGreaterThan: this.vehiclesFilters.paymentGreaterThan,
            prices: this.vehiclesFilters.prices,
          },
        });
      } else {
        this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
          reset: false,
          fields: {
            smallerThan: this.vehiclesFilters.smallerThan,
            greaterThan: this.vehiclesFilters.greaterThan,
            smallerThanRecall: this.vehiclesFilters.smallerThanRecall,
            greaterThanRecall: this.vehiclesFilters.greaterThanRecall,
            stockDateFrom: this.vehiclesFilters.stockDateFrom,
            stockDateTo: this.vehiclesFilters.stockDateTo,
            paymentSmallerThan: this.vehiclesFilters.paymentSmallerThan,
            paymentGreaterThan: this.vehiclesFilters.paymentGreaterThan,
            prices: this.vehiclesFilters.prices,
          },
        });
      }
      if (this.type != "prices") {
        let arrayToApply = this.convertArrayToString(this.filter);
        this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
          fields: { [this.formatTypeToFilter(this.type)]: arrayToApply },
        });
      }
      this.closeMenuFilter();
      this.refreshFunction();
    },
    clearFilter() {
      this.filter = [];
      if (this.type == "sold") {
        this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
          fields: { saleDateTO: null, saleDateFrom: null },
        });
      }
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: { [this.formatTypeToFilter(this.type)]: [] },
      });

      if (this.getDateFieldByType(this.type)) {
        this.clearDateFilter();
      }
      this.closeMenuFilter();
      this.refreshFunction();
    },
    clearDateFilter() {
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: {
          [this.getDateFieldByType(this.type).from]: null,
          [this.getDateFieldByType(this.type).to]: null,
        },
      });
    },
    getDateFieldByType(type) {
      switch (type) {
        case "payments":
          return { to: "paymentsTo", from: "paymentsFrom" };
        case "recall":
          return { to: "recallTo", from: "recallFrom" };
        case "lien":
          return { to: "liensTo", from: "liensFrom" };
        case "sold":
          return { to: "saleDateTo", from: "saleDateFrom" };
        case "stockNumber":
          return { to: "stockDateFrom", from: "stockDateTo" };
        case "visualInspection":
          return { to: "viTo", from: "viFrom" };
        case "workOrders":
          return { to: "woTo", from: "woFrom" };
        case "files":
          return { to: "exportReceivedDateFrom", from: "exportReceivedDateTo" };
        default:
          return null;
      }
    },
    convertArrayToString(array) {
      return array.join(",");
    },
    getFilterFromStore() {
      let filterStore =
        this.vehiclesFilters[this.formatTypeToFilter(this.type)];
      if (filterStore && filterStore != "" && typeof filterStore == "string") {
        this.filter = filterStore.split(",");
      } else {
        this.filter = [];
      }
    },
    onSelectAgent(agents) {
      this.filter = agents;
    },
    onSelectMarketplace(marketplaces) {
      this.filter = marketplaces;
    },
    closeMenuFilter() {
      this.$refs["filter-dropdown"].hide();
    },
    hasFilter() {
      if (this.filter.length > 0) {
        return true;
      }

      if (this.getDateFieldByType(this.type) == null) {
        return false;
      }
      if (
        this.vehiclesStore[this.getDateFieldByType(this.type).to] != null ||
        this.vehiclesStore[this.getDateFieldByType(this.type).from] != null
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.menu-min-width {
  min-width: 5rem !important;
}
.hideOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
</style>
