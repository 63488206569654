<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand bg-gradient-dark border border-top-0 border-right-0 border-left-0"
    :style="styleObject"
  >
    <!-- Search form -->
    <b-form
      v-if="!isMobile"
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      @submit.prevent
    >
      <b-form-group class="mb-0">
        <b-input-group class="input-group-alternative">
          <div
            class="input-group-prepend cursor-pointer"
            @click="search(searchText)"
          >
            <span class="input-group-text"
              ><i class="fas fa-search text-success"></i
            ></span>
          </div>
          <b-form-input
            placeholder="Search"
            v-model="searchText"
            :type="inputSearchType"
            @keydown.enter="search(searchText)"
          >
          </b-form-input>
          <div v-if="showAdvanceSearch" class="input-group-append">
            <span class="input-group-text">
              <font-awesome-icon
                class="cursor-pointer m-2 text-success"
                :class="{
                  'text-warning': hasAdvancedFilters(),
                  'text-success': !hasAdvancedFilters(),
                }"
                :icon="['far', 'magnifying-glass-plus']"
                v-b-toggle.advance-search-sidebar
            /></span>
          </div>
        </b-input-group>
      </b-form-group>
    </b-form>

    <a
      href="#"
      aria-current="page"
      class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block active router-link-active"
    >
    </a>
    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>

      <li>
        <b-form
          v-if="isMobile"
          class="navbar-search-show form-inline mr-sm-3"
          :class="{
            'navbar-search-light': type === 'default',
            'navbar-search-dark': type === 'light',
          }"
          @submit.prevent
        >
          <b-form-group class="mb-0">
            <b-input-group class="input-group-alternative">
              <div
                class="input-group-prepend cursor-pointer"
                @click="search(searchText)"
              >
                <span class="input-group-text"
                  ><i class="fas fa-search text-success"></i
                ></span>
              </div>
              <b-form-input
                placeholder="Search"
                v-model="searchText"
                :type="inputSearchType"
                @keydown.enter="search(searchText)"
              >
              </b-form-input>
              <div v-if="showAdvanceSearch" class="input-group-append">
                <span class="input-group-text">
                  <font-awesome-icon
                    class="cursor-pointer m-2 text-success"
                    :icon="['far', 'magnifying-glass-plus']"
                    v-b-toggle.advance-search-sidebar
                /></span>
              </div>
            </b-input-group>
          </b-form-group>
        </b-form>
      </li>
    </b-navbar-nav>

    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <b-media no-body class="align-items-center">
            <span class="avatar avatar-sm rounded-circle bg-white text-success">
              {{ initialsOfProfile }}
            </span>
            <b-media-body class="ml-2 d-none d-lg-block"> </b-media-body>
          </b-media>
        </a>

        <template>
          <b-dropdown-header class="noti-title">
            <!--             <h6 class="text-overflow m-0">Welcome!</h6>
 -->
          </b-dropdown-header>
          <b-dropdown-item @click="goTo('user.profile')">
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </b-dropdown-item>
          <b-dropdown-item @click="goTo('superadmin.tasks-user')">
            <i> <font-awesome-icon :icon="['far', 'list-check']" /> </i>
            <span>My tasks</span>
          </b-dropdown-item>
          <b-dropdown-item @click="goTo('user.account-statement')">
            <i>
              <font-awesome-icon :icon="['far', 'file-invoice-dollar']" />
            </i>
            <span>Account statement</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="userHasMenuPermission('DailyReports')"
            @click="goTo('superadmin.daily-reports')"
          >
            <i>
              <font-awesome-icon :icon="['far', 'file-chart-column']" />
            </i>
            <span>Daily reports</span>
          </b-dropdown-item>
          <div class="dropdown-divider"></div>
          <b-dropdown-item @click="logout()">
            <font-awesome-icon :icon="['far', 'right-from-bracket']" />

            <span>Logout</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import { BaseNav } from "@/components";
import { mapState } from "vuex";
import { superAdminMixin } from "../modules/superAdmin/mixins";

export default {
  components: {
    BaseNav,
  },
  mixins: [superAdminMixin],
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    ...mapState({
      searchFunction: (state) => state.layout.boards.searchFunction,
      filters: (state) => state.superAdminStore.vehiclesInfo.filters,
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },

    showAdvanceSearch() {
      return this.$route.name.includes("superadmin.vehicles.table");
    },
    inputSearchType() {
      return this.$route.name.includes("superadmin.credit-request")
        ? "date"
        : "text";
    },
    isMobile() {
      return this.$vssWidth <= 990 ? true : false;
    },
    initialsOfProfile() {
      if (
        !this.profile ||
        !this.profile.user ||
        !this.profile.user.firstName ||
        !this.profile.user.lastName
      ) {
        return "";
      }
      return (
        this.profile?.user?.firstName
          .split(" ")
          .map((n) => n[0])
          .join("") + this.profile?.user?.lastName[0]
      );
    },
    corporation() {
      const corporation = localStorage.getItem("corporation");
      let corporationSelect = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );

      return corporationSelect
        ? corporationSelect
        : this.profile.user.corporationPermissions[0]
        ? this.profile.user.corporationPermissions[0]
        : { name: "" };
    },
    styleObject() {
      return {
        "border-color": this.corporation.color
          ? this.corporation.color + "!important"
          : "white" + "!important",
      };
    },
  },
  watch: {
    filters: {
      handler() {
        this.formatSearch();
      },
      deep: true,
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      searchText: "",
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    logout() {
      this.$store
        .dispatch("AUTH_LOGOUT")
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$root.$bvToast.toast("Error to logout", {
            title: "Warning",
            variant: "danger",
          });
        });
    },
    search($event) {
      /* get function from the store getter*/
      this.$store.getters["getSearchFunction"]($event);
      this.scrollToTable();
    },
    formatSearch() {
      if (this.filters.search != null) {
        this.searchText = this.filters.search;
      } else {
        this.searchText = "";
      }
    },
    scrollToTable() {
      const element = document.getElementById("total-rows");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    goToProfile() {
      this.$router.push({ name: "user.profile" });
    },
    goTo(routeName) {
      if (this.$route.name == routeName) return;
      this.$router.push({ name: routeName });
    },
    hasAdvancedFilters() {
      const advancedFilters = [
        "location",
        "lastLocation",
        "search",
        "make",
        "model",
        "trim",
        "releaseDate1",
        "releaseDate2",
        "year1",
        "year2",
        "transmissionType",
        "lastLocation",
        "finalDestination",
        "boughtTo",
        "soldTo",
        "transferDestinations",
        "transferOrLocation",
      ];

      return Object.keys(this.filters).some(
        (key) =>
          advancedFilters.includes(key) &&
          this.filters[key] !== null &&
          (!Array.isArray(this.filters[key]) || this.filters[key].length > 0)
      );
    },
  },
  mounted() {
    this.searchText = this.filters.search ? this.filters.search : "";
  },
};
</script>

<style scoped>
.border-bottom-white {
  border-bottom: 0.1px solid #f8f9ff !important;
}
</style>
