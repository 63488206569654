<template>
  <b-form v-if="!isLoading" @submit.stop.prevent="form.id ? update() : save()">
    <b-row>
      <!-- Origin-->
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="11" class="mr-0">
            <b-form-group label-size="md">
              <template slot="label">
                Origin <span class="text-danger">*</span>
              </template>
              <vue-single-select
                :classes="{ input: 'form-control', dropdown: 'dropdown' }"
                v-model="origin"
                :options="origins"
                optionLabel="companyName"
              ></vue-single-select>
              <b-form-input
                class="mt-3"
                id="firstName-input"
                v-model.trim="$v.form.originAddress.$model"
                size="md"
                placeholder="Origin address"
                :value="form.originAddress"
                :state="validateState('originAddress')"
                aria-describedby="input-firstName-live-feedback"
                maxlength="100"
                @change="setValue('originAddress', $event)"
              />
              <b-form-invalid-feedback id="input-firstName-live-feedback">
                This is a required field and must at least 2 characters.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="1" class="pl-0">
            <b-icon
              icon="person-plus-fill"
              scale="1.5"
              class="ml-2 mt-4 mt-5"
              @click="showModal()"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12"> </b-col>

      <!-- Destination-->
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group label-size="sm">
          <template slot="label">
            Destination <span class="text-danger">*</span>
          </template>
          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="destination"
            :options="destinations"
            optionLabel="companyName"
          ></vue-single-select>
          <b-form-input
            class="mt-3"
            id="firstName-input"
            v-model.trim="$v.form.destinationAddress.$model"
            size="md"
            :value="form.destinationAddress"
            :state="validateState('destinationAddress')"
            aria-describedby="input-firstName-live-feedback"
            maxlength="100"
            @change="setValue('destinationAddress', $event)"
          />
          <b-form-invalid-feedback id="input-firstName-live-feedback">
            This is a required field and must at least 2 characters.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- CArrier -->
      <b-col sm="12" md="12" lg="6" xl="6">
        <b-form-group label-size="md">
          <template slot="label">
            <b-form-radio-group
              id="radio-group-2"
              v-model="typeCarrier"
              name="radio-sub-component"
            >
              <b-form-radio value="provider">Provider</b-form-radio>
              <b-form-radio value="driver"> Driver </b-form-radio>
              <b-form-radio value="customer"> Customer </b-form-radio>
            </b-form-radio-group>
          </template>
          <vue-single-select
            v-if="typeCarrier == 'provider'"
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.provider"
            :options="providers"
            optionLabel="companyName"
          ></vue-single-select>

          <vue-single-select
            v-if="typeCarrier == 'driver'"
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.driver"
            :options="drivers"
            :getOptionDescription="() => driverName"
          >
            <template slot="option" slot-scope="{ option }">
              <div>{{ option.firstName }} {{ option.lastName }}</div>
            </template>
          </vue-single-select>
        </b-form-group>
      </b-col>

      <!-- Type -->
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="form-group">
          <label for="">Type</label>
          <b-form-select v-model="form.type" :options="types"></b-form-select>
        </div>
      </b-col>

      <!-- cost -->
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template> Cost </template>
          <Money
            v-model="form.cost"
            class="form-control text-center"
            v-bind="money"
          />
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template> Estimated arrival date</template>
          <input
            v-model="form.deliveryDate"
            type="datetime-local"
            class="form-control"
          />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Note">
          <b-form-textarea v-model="form.note" class="resize-none" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
      <b-button
        :disabled="
          transfer.status === 'fetching' || vehiclesSelected.length == 0
        "
        variant="yellow"
        class="text-white my-1 px-5 mt-3"
        @click="registerAutomaticTransfer('purchase')"
        type="button"
      >
        <b-spinner
          v-if="transfer.status === 'fetching'"
          variant="white"
          small
        />
        <span v-else> Pickup </span>
      </b-button>
      <b-button
        :disabled="
          transfer.status === 'fetching' || vehiclesSelected.length == 0
        "
        variant="yellow"
        class="text-white my-1 px-5 mt-3 mx-2"
        @click="registerAutomaticTransfer('sale')"
        type="button"
      >
        <b-spinner
          v-if="transfer.status === 'fetching'"
          variant="white"
          small
        />
        <span v-else> Drop off </span>
      </b-button>
    </b-col>
    <VehiclesList
      @vehicles-selected="setVehiclesSelected"
      :vehiclesPreSelected="[]"
      class="h-25"
      @search-vehicles="getVehicles"
    />
    <div class="text-right mt-2">
      <div class="row" align-h="end">
        <b-col>
          <b-form-checkbox
            id="checkbox-1"
            v-model="form.consignment"
            name="checkbox-1"
            class="mx-2 mt-2"
            :value="true"
            :unchecked-value="false"
            :disabled="typeCarrier == 'customer'"
          >
            Consignment
          </b-form-checkbox>
        </b-col>
        <b-col cols="auto">
          <b-button
            :disabled="$v.form.$invalid || transfer.status === 'fetching'"
            variant="primary"
            type="submit"
          >
            <b-spinner
              v-if="transfer.status === 'fetching'"
              variant="white"
              small
            />
            <span v-else>Submit</span>
          </b-button>
        </b-col>
      </div>
    </div>
    <b-modal
      :id="'clientModal' + type"
      ref="clientModal"
      size="lg"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
    >
      <ClientForm :refresh-data="getOrigins" :hide-modal="hideModal" />
    </b-modal>
    <DownloadFileModal :vehicles="vehiclesSelected" />
  </b-form>
  <!-- loader -->
  <div v-else class="d-flex justify-content-center">
    <b-spinner label="Loading..." variant="yellow" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import {
  POST_BULK_TRANSFER,
  POST_QUICK_BULK_TRANSFER,
  GET_VEHICLES_BULK_TRANSFER,
} from "./actions";
import { superAdminMixin } from "../../mixins";
import { GET_PROVIDERS } from "@/modules/vehicle/components/work-orders/service-providers/actions";
import VueSingleSelect from "vue-single-select";
import ClientForm from "@/modules/vehicle/components/Clients/Form.vue";
import VehiclesList from "./VehiclesList.vue";
import DownloadFileModal from "./DownloadFileModal.vue";
import { Money } from "v-money";
import { generalMixin } from "../../../mixin";

export default {
  mixins: [superAdminMixin, generalMixin],
  props: ["vehicle", "refreshData"],
  components: {
    VueSingleSelect,
    Money,
    ClientForm,
    VehiclesList,
    DownloadFileModal,
  },
  data() {
    return {
      providers: [],
      drivers: [],
      typeCarrier: "provider",
      origin: null,
      destination: null,
      isLoading: false,
      form: {
        originAddress: null,
        destinationAddress: null,
        vehicleId: null,
        provider: null,
        driver: null,
        type: null,
        cost: 0,
        deliveryDate: null,
        kms: 0,
        destinationName: null,
        destinationStreet: null,
        destinationZip: null,
        destinationCity: null,
        destinationState: null,
        consignment: false,
        note: null,
        originId: null,
        destinationId: null,
      },
      /*       origins: [],
      destinations: [], */

      types: [
        { value: null, text: "Please select an option" },
        {
          value: "Internal pick-up transfer",
          text: "Internal pick-up transfer",
        },
        {
          value: "Internal delivery transfer",
          text: "Internal delivery transfer",
        },
        {
          value: "Work order delivery transfer",
          text: "Work order delivery transfer",
        },
        {
          value: "Work order pickup transfer",
          text: "Work order pickup transfer",
        },
      ],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "USD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      isKilometersEditing: false,
      type: "origin",
      vehiclesSelected: [],
    };
  },
  computed: {
    ...mapState({
      recall: (state) =>
        state.superAdminStore.componentStore.recall.recall.status,
      transfer: (state) => state.superAdminStore.bulkTransfer.bulkTransfer,
      origins: (state) =>
        state.superAdminStore.componentStore.transfer.origins.data,
      destinations: (state) =>
        state.superAdminStore.componentStore.transfer.origins.data,
      currentRiLocation: (state) =>
        state.superAdminStore.bulkTransfer.currentRiLocation,
    }),

    driverName() {
      if (this.form.driver) {
        return `${this.form.driver.firstName} ${this.form.driver.lastName}`;
      }
      return "";
    },
  },
  watch: {
    origin: function () {
      this.onChangeOrigin();
    },
    destination: function () {
      this.onChangeDestination();
    },
  },
  validations: {
    form: {
      originAddress: {
        required,
      },
      destinationAddress: {
        required,
      },
    },
  },

  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.description = null;
      this.form.vehicleId = null;
      this.form.consignment = false;
      this.form.cost = 0;
      this.form.note = null;
      this.form.originAddress = null;
      this.form.destinationAddress = null;
      this.form.vehicleId = null;
      this.form.provider = null;
      this.form.driver = null;
      this.form.type = null;
      this.form.cost = 0;
      this.form.deliveryDate = null;
      this.form.kms = 0;
      this.form.destinationName = null;
      this.form.destinationStreet = null;
      this.form.destinationZip = null;
      this.form.destinationCity = null;
      this.form.destinationState = null;
      this.form.consignment = false;
      this.form.note = null;
      this.form.originId = null;
      this.form.destinationId = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    getProviders() {
      this.$store
        .dispatch(GET_PROVIDERS)
        .then((response) => {
          this.providers = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the providers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getDrivers() {
      this.$store
        .dispatch("GET_DRIVER_LIST_NO_PAGINATE")
        .then((response) => {
          this.drivers = response.data;
        })
        .catch(() => {});
    },
    onChangeOrigin() {
      this.form.originAddress = null;
      this.form.originId = null;
      if (!this.origin) {
        return;
      }
      this.form.originAddress = this.origin?.companyName;
      this.form.originId = this.origin?.id;
      if (this.origin.street) {
        this.form.originAddress += `, ${this.origin.street}`;
      }
      if (this.origin.zip) {
        this.form.originAddress += `, ${this.origin.zip}`;
      }
      if (this.origin.city) {
        this.form.originAddress += `, ${this.origin.city}`;
      }
    },
    onChangeDestination() {
      this.form.destinationAddress = null;
      this.form.destinationId = null;
      if (!this.destination) {
        return;
      }
      this.form.destinationAddress = this.destination.companyName;
      this.form.destinationId = this.destination.id;
      if (this.destination.street) {
        this.form.destinationAddress += `, ${this.destination.street}`;
      }
      if (this.destination.zip) {
        this.form.destinationAddress += `, ${this.destination.zip}`;
      }
      if (this.destination.city) {
        this.form.destinationAddress += `, ${this.destination.city}`;
      }
      this.form.destinationName = this.destination.companyName;
      this.form.destinationStreet = this.destination.street;
      this.form.destinationZip = this.destination.zip;
      this.form.destinationCity = this.destination.city;
      this.form.destinationState = this.destination.state;
      this.form.companyId = this.destination.companyId
        ? this.destination.companyId
        : null;
    },

    formatPayload() {
      const payload = {
        origin: this.form.originAddress,
        destination: this.form.destinationAddress,
        type: this.form.type,
        cost: this.form.cost,
        deliveryDate: this.form.deliveryDate,
        kms: this.form.kms,
        destinationName: this.form.destinationName,
        destinationStreet: this.form.destinationStreet,
        destinationZip: this.form.destinationZip,
        destinationCity: this.form.destinationCity,
        destinationState: this.form.destinationState,
        consignment: this.form.consignment,
        note: this.form.note,
        companyId: this.form.companyId,
        vehicles: this.vehiclesSelected,
        originId: this.form.originId,
        destinationId: this.form.destinationId,
      };
      if (this.form.provider != null) {
        payload.serviceProviderId = this.form.provider.id;
      }

      if (this.form.driver != null) {
        payload.driverId = this.form.driver.id;
      }
      if (this.typeCarrier == "customer") {
        payload.customer = true;
      }
      return payload;
    },

    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_BULK_TRANSFER, payload)
        .then(() => {
          this.resetForm();
          this.openFileModal();
          this.toastMessage("Transfer saved", "Success", "success");
          /*           this.closeModal("bulk-transfer-modal");
           */ this.getVehiclesInfo();
        })
        .catch((error) => {
          this.toastMessage(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            "Warning",
            "warning"
          );
        });
    },
    update() {},

    getOrigins(search = "") {
      this.$store
        .dispatch("GET_ORIGINS", { search: search })
        .then((response) => {
          this.origin = response.find(
            (origin) => origin.id == this.currentRiLocation.data
          );
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDestinations(search) {
      this.$store.dispatch("GET_ORIGINS", { search: search }).then(() => {
        /*           this.destinations = response;
         */
      });
    },
    registerAutomaticTransfer(action) {
      const payload = {
        vehicles: this.vehiclesSelected,
        action: action,
      };
      this.$store.dispatch(POST_QUICK_BULK_TRANSFER, payload).then(() => {
        this.openFileModal();

        this.toastMessage("Transfer saved", "Success", "success");
        /*         this.closeModal("bulk-transfer-modal");
         */ this.getVehiclesInfo();
      });
    },
    hideModal() {
      this.$bvModal.hide("clientModal" + this.type);
    },
    showModal() {
      this.$bvModal.show("clientModal" + this.type);
    },
    openFileModal() {
      this.$bvModal.show("bulk-transfer-download-modal");
    },
    getVehicles(search = "") {
      this.$store
        .dispatch(GET_VEHICLES_BULK_TRANSFER, {
          search: search,
        })
        .then(() => {
          this.getOrigins("");
        });
    },
    setVehiclesSelected(vehicles) {
      this.vehiclesSelected = vehicles;
    },
    clearVehicles() {
      this.vehiclesSelected = [];
      this.getVehicles();
    },
  },
  mounted() {
    this.getProviders();
    this.getDrivers();
    /*     this.getDestinations("");
     */ this.getVehicles();
  },
};
</script>

<style></style>
