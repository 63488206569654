<template>
  <div>
    <small for="">Title received date</small>
    <FilterTypeDate
      fromType="exportReceivedDateFrom"
      toType="exportReceivedDateTo"
    />
    <!-- load number filter -->
    <b-form-group label-size="sm" label="Load number:" label-for="input-1">
      <b-form-input
        size="sm"
        v-model="exportLoads"
        type="text"
        @change="applyFilter()"
      ></b-form-input>
    </b-form-group>

    <!-- accordion for internal filters -->
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.filter-accordion variant="success"
        >Internal control</b-button
      >
    </b-card-header>
    <b-collapse
      id="filter-accordion"
      accordion="filter-accordion"
      role="tabpanel"
      class="mw-accordion"
    >
      <b-card-body>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm" label="PAPS number" class="mt-3">
              <b-form-input
                size="sm"
                v-model="papsNumber"
                class="form-control"
                @change="applyFilter()"
              />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm" label="Crossed date" class="mt-3">
              <b-form-input
                v-model="crossedDate"
                size="sm"
                type="date"
                class="form-control"
                @change="applyFilter()"
              />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group
              label-size="sm"
              label="Conformity sent data "
              class="mt-3"
            >
              <b-form-input
                v-model="conformitySentDate"
                type="date"
                size="sm"
                class="form-control"
                @change="applyFilter()"
              />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group
              label-size="sm"
              label="Eligible release date"
              class="mt-3"
            >
              <b-form-input
                v-model="eligibleReleaseDate"
                type="date"
                size="sm"
                class="form-control"
                @change="applyFilter()"
              />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm" label="Dispatch date" class="mt-3">
              <b-form-input
                v-model="dispatchedDate"
                type="date"
                size="sm"
                class="form-control"
                @change="applyFilter()"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </div>
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";
import { SET_VEHICLES_INFO_FILTERS } from "../actions";
import { mapState } from "vuex";

export default {
  name: "ExportReceivedDateFilter",
  components: { FilterTypeDate },

  computed: {
    ...mapState({
      filters: (state) => state.superAdminStore.vehiclesInfo.filters,
    }),
  },
  data() {
    return {
      exportLoads: null,
      papsNumber: null,
      crossedDate: null,
      conformitySentDate: null,
      eligibleReleaseDate: null,
      dispatchedDate: null,
    };
  },
  watch: {
    filters() {
      this.getFilters();
    },
  },
  methods: {
    applyFilter() {
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: {
          exportLoads: this.exportLoads ? this.exportLoads : null,
          papsNumber: this.papsNumber ? this.papsNumber : null,
          crossedDate: this.crossedDate ? this.crossedDate : null,
          conformitySentDate: this.conformitySentDate
            ? this.conformitySentDate
            : null,
          eligibleReleaseDate: this.eligibleReleaseDate
            ? this.eligibleReleaseDate
            : null,
          dispatchedDate: this.dispatchedDate ? this.dispatchedDate : null,
        },
      });
    },
    getFilters() {
      this.exportLoads = this.filters.exportLoads
        ? this.filters.exportLoads
        : null;
      this.papsNumber = this.filters.papsNumber
        ? this.filters.papsNumber
        : null;
      this.crossedDate = this.filters.crossedDate
        ? this.filters.crossedDate
        : null;
      this.conformitySentDate = this.filters.conformitySentDate
        ? this.filters.conformitySentDate
        : null;
      this.eligibleReleaseDate = this.filters.eligibleReleaseDate
        ? this.filters.eligibleReleaseDate
        : null;
      this.dispatchedDate = this.filters.dispatchedDate
        ? this.filters.dispatchedDate
        : null;
    },
  },
};
</script>

<style scoped>
.mw-accordion {
  max-width: 360px !important;
}
</style>
