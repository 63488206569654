<template>
  <b-dropdown
    v-model="status"
    :text="this.status ? this.status : 'Any status'"
    variant="white"
    class="m-2 mb-2"
    menu-class="w-100"
  >
    <b-dropdown-item
      @click="
        status = null;
        setStatus(null);
      "
    >
      Any status
    </b-dropdown-item>
    <b-dropdown-item
      v-for="(s, i) of statusList"
      :key="i"
      @click="setStatus(s)"
    >
      {{ s }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      status: "InStock",
      statusList: ["InStock", "Sold", "Consignment"],
    };
  },
  computed: {
    ...mapState({
      filters: (state) => state.superAdminStore.vehiclesInfo.filters,
    }),
  },
  watch: {
    filters: {
      handler() {
        this.status = this.filters.status;
      },
      deep: true,
    },
  },
  mounted() {
    this.status = this.filters.status;
  },

  methods: {
    setStatus(status) {
      this.status = status;
      this.$store.commit("SET_VEHICLES_INFO_FILTERS", {
        reset: false,
        fields: { status: this.status },
      });
      this.getVehicles();
    },
    getVehicles() {
      this.$store
        .dispatch("GET_VEHICLES_INFO", {})
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the vehicle list", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style></style>
