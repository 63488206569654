import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import componentStore from "./components/store";
import confirmationChecks from "./checkConfirmation/store";
import driver from "./driver/store";
import taxes from "./taxes/store";
import fees from "./fees/store";
import exportLoad from "./exportLoad/store";
import exportLocation from "./exportLocation/store";
import location from "./location/store";
import creditRequest from "./creditRequest/store";
import registration from "./registration/store";
import permissions from "./permissions/store";
import fx from "./fx/store";
import exportPort from "./exportPort/store";
import addonsType from "./addons-type/store"
import bulkCluster from "./bulk-actions/cluster/store"
import bulkTransfer from "./bulk-actions/transfers/store"
import bulkReleaseDate from "./bulk-actions/release-date/store"
import addonsFile from "./addons-file/store"
import addonsAssign from "./addons-type-assign/store"
import vehicleTimeline from "./timeline/store"
import accounting from "./accounting/store"
import exchangeContract from "./exchange-contract/store"
import interest from "./interest/store"
import agentCommission from "./agent-commissions/store"
import accountStatement from "./account-statement/store"
import tasks from "./tasks/store"
import reports from "./reports/store"
import customReports from "./custom-reports/store"
import runList from "./run-list/store"
import transferBetweenAgent from "./transfer-between-agent/store"
import invoice from "./invoice/store"
import transportation from "./transportation/store"
import reserve from "./reserve/store"
import titleManager from "./title-manager/store"
import driverPrice from "./driver-price/store"
import commissionSummary from "./commissions-summary/store"
import riCompanies from "./ri/store"
import reImport from "./re-import/store"
function getDefaultState() {
  return {
    vehiclesInfo: {
      status: null,
      data: [],
      error: null,
      filters: {
        search: null,
        resourcesPerPage: 25,
        page: 1,
        status: null,
        marketPlace: null,
        payments: [],
        carfax: [],
        recall: [],
        visualInspection: [],
        workOrders: [],
        carwash: [],
        agentId: [],
        transfer: [],
        stock: [],
        lien: [],
        agent: null,
        client: null,
        vinNumber: null,
        location: null,
        make: null,
        model: null,
        trim: null,
        transmissionType: null,
        releaseDate1: null,
        releaseDate2: null,
        year1: null,
        year2: null,

        trackingStatus: null,
        new: null,
        vehicleId: null,

        /* liens */
        liensFrom: null,
        liensTo: null,

        creditRequest: null,

        smallerThanRecall: null,
        greaterThanRecall: null,

        /* stock */
        stockDateFrom: null,
        stockDateTo: null,

        /* Payments */
        paymentSmallerThan: null,
        paymentGreaterThan: null,
        paymentsFrom: null,
        paymentsTo: null,
        prices: null,

        /* Sold */
        saleDateFrom: null,
        saleDateTo: null,
        sold: null,
        /* Cluster */
        cluster: null,
        reserve: null,

        /* Work order */
        woFrom: null,
        woTo: null,

        /* Visual inspection */
        viFrom: null,
        viTo: null,

        /* Recall */
        recallFrom: null,
        recallTo: null,

        /* Receiver date (column files) */
        exportReceivedDateFrom: null,
        exportReceivedDateTo: null,
        exportLoads: null,
        papsNumber: null,
        crossedDate: null,
        conformitySentDate: null,
        eligibleReleaseDate: null,
        dispatchDate: null,

        id: null,
      },
    },
    vehicle: null,
    vehiclesExcel: {
      status: null,
      data: {},
      error: null,
    },
    vehicleAnchor: null,
    quickTransfer: {
      status: null,
      data: {},
      error: null,
    }
  };
}

const actions = {
  [constants.GET_VEHICLES_INFO]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLES_INFO);
      const payload = Object.entries(state.vehiclesInfo.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("vehicle/getForTable", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_INFO_SUCCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_INFO_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_VEHICLES_INFO_FOR_RI_ROLE]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLES_INFO);
      const payload = Object.entries(state.vehiclesInfo.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("ri/getVehiclesRi", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_INFO_SUCCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_INFO_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLES_EXCEL]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLES_EXCEL);
      const payload = Object.entries(state.vehiclesInfo.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("vehicle/createExcelFromQuery", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_EXCEL_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_CREATE_QUICK_RI_TRANSFER]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_CREATE_QUICK_RI_TRANSFER);

      const response = await axios.post("ri/quickTransfer", payload
      );
      commit(constants.POST_CREATE_QUICK_RI_TRANSFER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_CREATE_QUICK_RI_TRANSFER_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_VEHICLES_INFO]: (state) => {
    state.vehiclesInfo.status = "fetching";
    state.vehiclesInfo.error = null;
  },
  [constants.GET_VEHICLES_INFO_SUCCCESS]: (state, data) => {
    state.vehiclesInfo.status = "success";
    state.vehiclesInfo.error = null;
    state.vehiclesInfo.data = data;
  },
  [constants.GET_VEHICLES_INFO_ERROR]: (state, error) => {
    state.vehiclesInfo.status = "error";
    state.vehiclesInfo.error = error;
  },
  [constants.GET_VEHICLES_INFO_FOR_RI_ROLE]: (state) => {
    state.vehiclesInfo.status = "fetching";
    state.vehiclesInfo.error = null;
  },
  [constants.GET_VEHICLES_INFO_FOR_RI_ROLE_SUCCESS]: (state, data) => {
    state.vehiclesInfo.status = "success";
    state.vehiclesInfo.error = null;
    state.vehiclesInfo.data = data;
  },
  [constants.GET_VEHICLES_INFO_FOR_RI_ROLE_ERROR]: (state, error) => {
    state.vehiclesInfo.status = "error";
    state.vehiclesInfo.error = error;
  },
  [constants.SET_VEHICLE_INFO]: (state, data) => {
    state.vehicle = data;
  },
  [constants.SET_VEHICLES_INFO_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.vehiclesInfo.filters = {
        search: null,
        resourcesPerPage: 25,
        page: 1,
      };
    }
    if (filters.fields) {
      state.vehiclesInfo.filters = {
        ...state.vehiclesInfo.filters,
        ...filters.fields,
      };
    }
  },

  [constants.GET_VEHICLES_EXCEL]: (state) => {
    state.vehiclesExcel.status = "fetching";
    state.vehiclesExcel.error = null;
  },
  [constants.GET_VEHICLES_EXCEL_SUCCESS]: (state, data) => {
    state.vehiclesExcel.status = "success";
    state.vehiclesExcel.error = null;
    state.vehiclesExcel.data = data;
  },
  [constants.GET_VEHICLES_EXCEL_ERROR]: (state, error) => {
    state.vehiclesExcel.status = "error";
    state.vehiclesExcel.error = error;
  },
  [constants.SET_VEHICLE_ANCHOR]: (state, data) => {
    state.vehicleAnchor = data;
  },
  [constants.UPDATE_VEHICLE_FLAG]: (state, data) => {
    state.vehiclesInfo.data.paginate.data[data.index].vehicleFlags[data.flag] =
      data.value;
  },
  [constants.UPDATE_VEHICLE_RELEASE_DATE]: (state, data) => {
    state.vehiclesInfo.data.paginate.data[data.index].releaseDate = data.releaseDate;
  },
  [constants.POST_CREATE_QUICK_RI_TRANSFER]: (state) => {
    state.quickTransfer.status = "fetching";
    state.quickTransfer.error = null;
  },
  [constants.POST_CREATE_QUICK_RI_TRANSFER_SUCCESS]: (state, data) => {
    state.quickTransfer.status = "success";
    state.quickTransfer.error = null;
    state.quickTransfer.data = data;
  },
  [constants.POST_CREATE_QUICK_RI_TRANSFER_ERROR]: (state, error) => {
    state.quickTransfer.status = "error";
    state.quickTransfer.error = error;
  },

};

const getters = {
  getFilters() {
    return (state) => state.vehiclesInfo.filters;
  },
};

export default {
  state: getDefaultState(),
  actions,

  mutations,
  getters,
  modules: {
    componentStore,
    confirmationChecks,
    driver,
    taxes,
    fees,
    exportLoad,
    exportLocation,
    location,
    creditRequest,
    registration,
    permissions,
    fx,
    exportPort,
    addonsType,
    bulkCluster,
    bulkTransfer,
    addonsFile,
    addonsAssign,
    vehicleTimeline,
    accounting,
    exchangeContract,
    interest,
    agentCommission,
    accountStatement,
    tasks,
    reports,
    bulkReleaseDate,
    customReports,
    runList,
    transferBetweenAgent,
    invoice,
    transportation,
    reserve,
    titleManager,
    driverPrice,
    commissionSummary,
    riCompanies,
    reImport
  },
};
